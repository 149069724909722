import { HttpClient, HTTP_METHODS } from "./http-client.interface";
import { HttpRequestOptions } from './http-client.interface'
import BaseHttpClient from './base-http-client';
import { BadRequest } from "./errors/bad-request.error";
import { Unauthenticated } from "./errors/unauthenticated.error";
import { Unauthorized } from "./errors/unauthorized.error";
import { ResourceNotFound } from "./errors/resource-not-found.error";
import { ServerError } from "./errors/server-error.error";

class MainHttpClient extends BaseHttpClient implements HttpClient {
  constructor(
    private baseUrl: string,
    private fetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>
  ) {
    super()
  }

  public async get(url: string, options?: HttpRequestOptions): Promise<any> {
    const query = this.formatQueryString(options?.query)
    try {
      const response = await this.fetch(this.baseUrl + url + query, {
        ...options,
        method: HTTP_METHODS.GET,
        credentials: 'include'
      })
      return this.parseResponse(response)
    } catch (error) {
      throw new Error('service exception' + error.message)
    }
  }

  public async put(url: string, options: HttpRequestOptions): Promise<any> {
    try {
      const response = await this.fetch(this.baseUrl + url, {
        ...options,
        headers: {
          ...options.headers,
          'Content-Type': 'application/json'
        },
        method: HTTP_METHODS.PUT,
        credentials: 'include'
      })
      return this.parseResponse(response)
    } catch (error) {
      throw new Error('service exception')
    }
  }

  public async post(url: string, options: HttpRequestOptions): Promise<any> {
    try {
      const response = await this.fetch(this.baseUrl + url, {
        ...options,
        headers: {
          ...options.headers,
          'Content-Type': 'application/json'
        },
        method: HTTP_METHODS.POST,
        credentials: 'include'
      })
      return this.parseResponse(response)
    } catch (error) {
      throw new Error('service exception')
    }
  }

  public async delete(url: string, options: HttpRequestOptions): Promise<any> {
    const query = this.formatQueryString(options?.query)
    try {
      const response = await this.fetch(this.baseUrl + url + query, {
        ...options,
        headers: {
          ...options.headers,
          'Content-Type': 'application/json'
        },
        method: HTTP_METHODS.DELETE,
        credentials: 'include'
      })
      return this.parseResponse(response)
    } catch (error) {
      throw new Error('service exception')
    }
  }

  private async parseResponse(response: Response): Promise<any> {
    if (response.status === 302) {
      const location = response.headers.get('Location')
      if (location !== null) {
        return window.location.replace(location)
      }
    }
    if (response.status === 400) {
      throw new BadRequest()
    }
    if (response.status === 401) {
      throw new Unauthenticated()
    }
    if (response.status === 403) {
      throw new Unauthorized()
    }
    if (response.status === 404) {
      throw new ResourceNotFound()
    }
    if (response.status === 500) {
      throw new ServerError()
    }
    const contentType = response.headers.get("content-type")
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json()
    }
    return response
  }
}

export default MainHttpClient
