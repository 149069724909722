import React from 'react'
import ReportDetailsSectionSelector from './report-details-section-selector/report-details-section-selector'
import { Paper, Typography } from '@material-ui/core'
import { fontSizes } from '../../../shared/theme/font-sizes.theme'
import VictimSectionDetails from './victim-section-details/victim-section-details'
import { useReportDetailsReportContentController } from './report-details-report-content.controller'
import { SectionId } from '../../../shared/types/section-id.type'
import DateAndLocationSectionDetails from './date-and-location-section-details/date-and-location-section-details'
import VehicleSectionDetails from './vehicle-seciton-details/vehicle-section-details'
import EventsSectionDetails from './events-section-details/events-section-details'
import SuspectSectionDetails from './suspect-section-details/suspect-section-details'
import colorPalette from '../../../shared/theme/color-palette.theme'

const ReportDetailsReportContent = () => {

  const { selectedSection } = useReportDetailsReportContentController()

  const renderSelectedSectionDetails = (sectionId: SectionId | undefined) => {
    switch (sectionId) {
      case 'victim':
        return <VictimSectionDetails />
      case 'dateLocation':
        return <DateAndLocationSectionDetails />
      case 'vehicle':
        return <VehicleSectionDetails />
      case 'suspect':
        return <SuspectSectionDetails />
      case 'events':
        return <EventsSectionDetails />
      default:
        return null
    }
  }

  return (
    <div>
      <div style={{ padding: '20px 0' }}>
        <Paper style={{ background: colorPalette.tintedWhite, padding: '20px' }} elevation={0}>
          <Typography style={{ fontSize: fontSizes[16], fontWeight: 'bold' }}>Report Details</Typography>
        </Paper>
      </div>
      <ReportDetailsSectionSelector />
      <div style={{ paddingBottom: '40px' }}>
        {renderSelectedSectionDetails(selectedSection)}
      </div>
    </div>
  )
}

export default ReportDetailsReportContent

