import { useState, useEffect } from "react"
import ServiceLocator from "../../../../shared/service-locator"
import { SectionId } from "../../../../shared/types/section-id.type"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService
const sectionSelectorService = ServiceLocator.instance.sectionSelectorService

export function useReportDetailsSectionSelectorController() {

  const [ sections, setSections ] = useState<Array<SectionId>|undefined>(
    reviewerReportDetailsService.reviewerReportDetailsSummary?.sections
  )

  const [ selectedSection, setSelectedSection ] = useState<SectionId|undefined>(sectionSelectorService.selectedSection)

  useEffect(() => {
    const detailsUpdateSubscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      setSections(reviewerReportDetailsService.reviewerReportDetailsSummary?.sections)
      setSelectedSection(reviewerReportDetailsService.reviewerReportDetailsSummary?.sections[0])
      detailsUpdateSubscription.cancel()
    })

    const selectedSectionUpdateSubscription = sectionSelectorService.subscribeToSelectedSectionUpdate(() => {
      setSelectedSection(sectionSelectorService.selectedSection)
    })

    return () => {
      detailsUpdateSubscription.cancel()
      selectedSectionUpdateSubscription.cancel()
    }
  }, [])

  const handleSelectionChange = (_: any, sectionId: SectionId) => {
    sectionSelectorService.setSelectionSection(sectionId)
  }

  return {
    sections,
    selectedSection,
    handleSelectionChange
  }
}
