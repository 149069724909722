import React from 'react'
import ReportCodeSearchBox from '../report-code-search-box/report-code-search-box'
import ReportFilterDropdown from '../report-filter-dropdown/report-filter-dropdown'
import ReviewerReportsTable from '../reviewer-reports-table/reviewer-reports-table'

const OverviewPage = () => {
  return (
    <div role="main">
      <div style={{ width: '90%', margin: '0 auto', paddingTop: '50px' }}>
        <div style={{ display: 'flex' }}>
          <ReportCodeSearchBox />
          <ReportFilterDropdown />
        </div>
        <div style={{ marginTop: '30px' }}>
          <ReviewerReportsTable />
        </div>
      </div>
    </div>
  )
}

export default OverviewPage
