import ServiceLocator from "../../../shared/service-locator"
import { useEffect, useState } from "react"
import { SectionId } from "../../../shared/types/section-id.type"

const sectionSelectorService = ServiceLocator.instance.sectionSelectorService
const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService

export function useReportDetailsReportContentController() {

  const [ selectedSection, setSelectedSection ] = useState<SectionId|undefined>(sectionSelectorService.selectedSection)

  useEffect(() => {
    const subscription = sectionSelectorService.subscribeToSelectedSectionUpdate(() => {
      setSelectedSection(sectionSelectorService.selectedSection)
    })

    const detailsUpdateSubscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      setSelectedSection(reviewerReportDetailsService.reviewerReportDetailsSummary?.sections[0])
      detailsUpdateSubscription.cancel()
    })

    return () => {
      subscription.cancel()
      detailsUpdateSubscription.cancel()
    }
  }, [])
  
  return {
    selectedSection
  }
}
