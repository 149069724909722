import React from 'react'
import { useVictimSectionDetailsController } from './victim-section-details.controller'
import { victimSectionQuestions as questions } from './victim-section-details.content'
import GridHead from '../../../../shared/components/report-details-grid-head'
import ReportDetailsQuestions from '../../../../shared/components/report-details-questions'

const VictimSectionDetails = () => {

  const {
    sectionDetails
  } = useVictimSectionDetailsController()

  return (
    <div>
      <GridHead />
      <ReportDetailsQuestions sectionDetails={sectionDetails} questions={questions} />
    </div>
  )
}

export default VictimSectionDetails
