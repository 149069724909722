import { useState, useEffect } from "react";
import { ReviewStatusType } from "../../models/reviewer-report.model";
import ServiceLocator from "../../../shared/service-locator";

const REVIEW_STATUS_TYPES: ReadonlyArray<ReviewStatusType> = [
  'Unopened',
  'In progress',
  'Reviewed',
  'Submitted'
]

const reviewerReportsManager = ServiceLocator.instance.reviewerReportsManager

export function useReportFilterDropdownController() {

  const [filteringStatus, setFilteringStatus] = useState<string[]>([]);

  useEffect(() => {
    const subscription = reviewerReportsManager
      .subscribeToFilteringStatusUpdate(() => {
        setFilteringStatus(reviewerReportsManager.filteringStatus)
      })

    return () => {
      subscription.cancel()
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const filteringOptions = event.target.value as ReviewStatusType[]
    reviewerReportsManager.setFilteringOptions(filteringOptions)
  };

  return {
    filteringStatus,
    reviewStatusTypes: REVIEW_STATUS_TYPES,
    handleChange
  }
}
