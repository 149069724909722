export type HttpRequestOptions = {
  [key:string]: any
}

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export interface HttpClient {
  get(url: string, options?: HttpRequestOptions): Promise<any>

  put(url: string, options?: HttpRequestOptions): Promise<any>

  post(url: string, options?: HttpRequestOptions): Promise<any>

  delete(url: string, options?: HttpRequestOptions): Promise<any>
}
