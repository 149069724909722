import React, { FunctionComponent } from 'react'

const QuestionValueMargin: FunctionComponent = ({ children }) => {
  return (
    <div style={{ margin: '6px 0', display: 'flex' }}>
      {children}
    </div>
  )
}

export default QuestionValueMargin
