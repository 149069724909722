import ServiceLocator from "../../../shared/service-locator"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService

export function useReportDetailsPageController() {

  const [ hasTargetReportCode, setHasTargetReportCode ] = useState<boolean>(false)

  const history = useHistory()

  useEffect(() => {
    if (!reviewerReportDetailsService.targetReportCode) {
      history.push('/overview')
    } else {
      setHasTargetReportCode(true)
    }
  }, [])

  return {
    hasTargetReportCode
  }
}