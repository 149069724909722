import moment from 'moment'
import uniq from 'lodash/uniq'
import { HttpClient } from "../../../shared/clients/http-client/http-client.interface";
import { ReviewerReportDetailsSummary } from "../../models/reviewer-report-details-summary.model";
import { CancelableSubscription } from "../../../shared/interfaces/cancelable-subscription.interface";
import { Subscribable } from "../../../shared/base-classes/subscribable";
import { Subject } from "rxjs";
import { GetReviewerReportDetailsSummaryResponseType } from "./response-types/reviewer-report-details.response-type";
import { ReviewerReportDetailsService, StatusUpdateHistory, Comments } from './reviewer-report-details-service.interface';
import { ReviewStatusType } from '../../../overview/models/reviewer-report.model';
import { SectionId } from '../../../shared/types/section-id.type';

export class MainReviewerReportDetailsService extends Subscribable implements ReviewerReportDetailsService {

  private _targetReportCode: string | null = null;
  private _targetReviewerReportId: string | null = null;
  private _reviewerReportDetailsSummary: ReviewerReportDetailsSummary | null = null;
  private _statusUpdateHistory: StatusUpdateHistory = [];
  private _comments: Comments = [];

  private _reviewerReportDetailsSummaryUpdate: Subject<void> = new Subject<void>();
  private _statusUpdateHistoryUpdate: Subject<void> = new Subject<void>();
  private _commentsUpdate: Subject<void> = new Subject<void>();
  private _reviewerStatusUpdate: Subject<void> = new Subject<void>()

  constructor(
    private httpClient: HttpClient
  ) {
    super()
  }

  public async getReviewerReportDetailsSummary(): Promise<void> {
    const reviewerReportDetailsSummary: GetReviewerReportDetailsSummaryResponseType = await this.httpClient.get('/api/reviewer/view-report-details-summary', {
      query: {
        reviewerReportId: this._targetReviewerReportId
      }
    })

    const sections: SectionId[] = []

    reviewerReportDetailsSummary.sections.forEach((section) => {
      if (section === 'date' as SectionId || section === 'location' as SectionId ) {
        sections.push('dateLocation')
      } else if (section === 'sequenceOfEvents' as SectionId) {
        sections.push('events')
      } else {
        sections.push(section)
      }
    })

    const uniqueSections = uniq(sections)

    this._reviewerReportDetailsSummary = new ReviewerReportDetailsSummary(
      reviewerReportDetailsSummary.reviewerReportId,
      reviewerReportDetailsSummary.shortCode,
      moment(reviewerReportDetailsSummary.submitDate).format("YYYY/MM/DD"),
      reviewerReportDetailsSummary.latestReviewerStatus.status as ReviewStatusType,
      reviewerReportDetailsSummary.latestReviewerStatus.lastUpdatedBy,
      reviewerReportDetailsSummary.latestReviewerStatus.updatedOn,
      uniqueSections,
      reviewerReportDetailsSummary.purgeDate
        ? moment(reviewerReportDetailsSummary.purgeDate)?.format("YYYY/MM/DD")
        : undefined,
    )

    this._reviewerReportDetailsSummaryUpdate.next()
  }

  public async updateReviewerStatus(reviewerStatus: ReviewStatusType): Promise<any> {
    await this.httpClient.post('/api/reviewer/update-reviewer-status', {
      body: JSON.stringify({
        reviewerReportId: this._reviewerReportDetailsSummary?.reviewerReportId,
        status: reviewerStatus
      })
    })

    await this.getStatusUpdateHistory()
    await this.getReviewerReportDetailsSummary()
    this._reviewerStatusUpdate.next()

  }

  public async getStatusUpdateHistory(): Promise<void> {
    const statusUpdateHistory = await this.httpClient.get('/api/reviewer/view-status-update-history', {
      query: {
        reviewerReportId: this._targetReviewerReportId
      }
    })

    this._statusUpdateHistory = statusUpdateHistory
    this._statusUpdateHistoryUpdate.next()
  }

  public async getComments(): Promise<void> {
    const comments = await this.httpClient.get('/api/reviewer/view-reviewer-comments', {
      query: {
        reviewerReportId: this._targetReviewerReportId
      }
    })

    this._comments = comments
    this._commentsUpdate.next()
  }

  public async addComment(comment: string): Promise<void> {
    await this.httpClient.post('/api/reviewer/add-reviewer-comment', {
      body: JSON.stringify({
        reviewerReportId: this._targetReviewerReportId,
        reviewerComment: comment
      })
    })

    await this.getComments()
  }

  public async deleteReportData(): Promise<void> {
    return this.httpClient.delete('/api/reviewer/purge-report-data', {
      query: {
        reviewerReportId: this._targetReviewerReportId
      }
    })
  }

  public subscribeToReviewerReportDetailsSummaryUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._reviewerReportDetailsSummaryUpdate, callback)
  }

  public subscribeToStatusUpdateHistoryUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._statusUpdateHistoryUpdate, callback)
  }

  public subscribeToCommentsUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._commentsUpdate, callback)
  }

  public subscribeToReviewerStatusUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._reviewerStatusUpdate, callback)
  }

  public setTargetReportCode(reportCode: string) {
    this._targetReportCode = reportCode
  }

  public setTargetReviewerReportId(reviewerReportId: string) {
    this._targetReviewerReportId = reviewerReportId
  }

  public get targetReportCode() {
    return this._targetReportCode
  }

  public get targetReviewerReportId() {
    return this._targetReviewerReportId
  }

  public get reviewerReportDetailsSummary(): ReviewerReportDetailsSummary | null {
    return this._reviewerReportDetailsSummary
  }

  public get statusUpdateHistory(): StatusUpdateHistory {
    return this._statusUpdateHistory
  }

  public get comments(): Comments {
    return this._comments
  }
}
