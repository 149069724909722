import React, { FunctionComponent } from 'react'
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import { REVIEW_STATUS_TYPES } from '../../../../overview/models/reviewer-report.model'
import { useReviewStatusDropdown } from './review-status-dropdown.controller'

const ReviewStatusDropdown: FunctionComponent = () => {

  const { reviewStatusType, handleSelectionChange } = useReviewStatusDropdown()

  return (
    <div style={{ width: '220px' }}>
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel>Change Status</InputLabel>
        <Select
          aria-label="filter by change status"
          value={reviewStatusType}
          onChange={handleSelectionChange}
          label="Change Status"
        >
          {REVIEW_STATUS_TYPES.map((type) => {
            return (
              <MenuItem key={type} value={type}>{type}</MenuItem>      
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}

export default ReviewStatusDropdown
