import React from 'react'
import { useSuspectSectionDetailsController } from './suspect-section-details.controller'
import { suspectSectionQuestions as questions } from './suspect-section-details.content'
import GridHead from '../../../../shared/components/report-details-grid-head'
import ReportDetailsQuestions from '../../../../shared/components/report-details-questions'
import { Typography } from '@material-ui/core'
import { fontSizes } from '../../../../shared/theme/font-sizes.theme'

const SuspectSectionDetails = () => {

  const {
    sectionDetails: suspectSectionDetails
  } = useSuspectSectionDetailsController()

  return (
    <div>
      <GridHead />
      {Object.entries(suspectSectionDetails).map(([suspectId, sectionDetails]) => {
        return (
          <div key={suspectId}>
            <Typography style={{ paddingTop: '12px', fontSize: fontSizes[18], fontWeight: 'bold', textDecoration: 'underline' }}>Suspect {suspectId}</Typography>
            <ReportDetailsQuestions sectionDetails={sectionDetails} questions={questions} />
            <hr />
          </div>
        )
      })}
    </div>
  )
}

export default SuspectSectionDetails
