import { Question } from "../../../../shared/interfaces/question.interface"

export const dateAndLocationSectionPartialQuestionTitle = 'Do you recall when this happened most recently? Even approximate or partial information can help.'

export const dateAndLocationSectionQuestions: Array<Question> = [
  {
    inputFieldId: 1,
    content: 'Did this happen more than once?',
  },
  {
    inputFieldId: 2,
    content: 'Do you recall approximately when it happened?'
  },
  {
    inputFieldId: 3,
    content: 'Year'
  },
  {
    inputFieldId: 4,
    content: 'Month'
  },
  {
    inputFieldId: 5,
    content: 'Day'
  },
  {
    inputFieldId: 6,
    content: 'Time of day'
  },
  {
    inputFieldId: 7,
    content: 'Do you know the address of this location?'
  },
  {
    inputFieldId: 8,
    content: 'Are you comfortable sharing the address in this report?'
  },
  {
    inputFieldId: 9,
    content: 'City'
  },
  {
    inputFieldId: 10,
    content: 'Province'
  },
  {
    inputFieldId: 11,
    content: 'Do you recall any distinct features about the location?'
  },
]
