import { Subscribable } from "../../../shared/base-classes/subscribable";
import { SectionId } from "../../../shared/types/section-id.type";
import { Subject } from "rxjs";

export class SectionSelectorService extends Subscribable {
  
  constructor(
    private _selectedSection: SectionId | undefined = undefined,
    private _selectedSectionUpdate: Subject<void> = new Subject<void>()
  ) {
    super()
  }

  public subscribeToSelectedSectionUpdate(callback: () => any) {
    return this.subscribeTo(this._selectedSectionUpdate, callback)
  }

  public setSelectionSection(sectionId: SectionId) {
    this._selectedSection = sectionId
    this._selectedSectionUpdate.next()
  }

  public get selectedSection() {
    return this._selectedSection
  }
}
