import { Question } from "../../../../shared/interfaces/question.interface";

export const vehicleSectionQuestions: Array<Question> = [
  {
    inputFieldId: 1,
    content: 'Was a vehicle involved?',
  },
  {
    inputFieldId: 2,
    content: 'Make'
  },
  {
    inputFieldId: 3,
    content: 'Model'
  },
  {
    inputFieldId: 4,
    content: 'Colour'
  },
  {
    inputFieldId: 5,
    content: 'Vehicle style'
  },
  {
    inputFieldId: 6,
    content: 'License plate'
  },
  {
    inputFieldId: 7,
    content: 'Issuing Province or Territory'
  },
  {
    inputFieldId: 8,
    content: 'Do you recall any distinct features / modifications about the vehicle? Anything you saw like condition, modifications, or marks on the vehicle could help build a picture.'
  },
]
