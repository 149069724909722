import { Subject } from "rxjs";
import { ReviewerReportsService, SortingOption, SortingDirection } from "../services/reviewer-reports-service/reviewer-reports-service.interface";
import { ReviewerReport, ReviewStatusType } from "../models/reviewer-report.model";
import { PaginationStatus, ReviewerReportsManager, SortingStatus, RowsPerPageOption } from "./reviewer-reports-manager.interface";
import { CancelableSubscription } from "../../shared/interfaces/cancelable-subscription.interface";
import { Subscribable } from "../../shared/base-classes/subscribable";

export const ROWS_PER_PAGE_OPTIONS: Array<RowsPerPageOption> = [10, 20, 30]

class MainReviewerReportsManager extends Subscribable implements ReviewerReportsManager {

  private _sortingStatus: SortingStatus = {
    sortBy: 'submitDate',
    sortDirection: 'desc'
  }
  private _paginationStatus: PaginationStatus = {
    currentPage: 0,
    rowsPerPage: 10
  }
  private _reportCodeSearchValue: string = ''
  private _filteringStatus: Array<ReviewStatusType> = []

  private _visibleReviewerReports: ReviewerReport[] = []
  private _totalNumberOfReportsInTheDatabase: number = 0  

  private _sortingStatusUpdate: Subject<void> = new Subject<void>()
  private _paginationStatusUpdate: Subject<void> = new Subject<void>()
  private _visibleReviewerReportsUpdate: Subject<void> = new Subject<void>()
  private _reportCodeSearchValueUpdate: Subject<void> = new Subject<void>()
  private _filteringStatusUpdate: Subject<void> = new Subject<void>()

  private updateVisibleReviewerReports(reviewerReports: ReviewerReport[]) {
    // set the reviwer reports with the new reviewer reports
    this._visibleReviewerReports = reviewerReports

    // // set the total reviwer reports number
    // this._totalNumberOfReportsInTheDatabase = totalNumberOfReportsInTheDatabase

    // push reviewer reports update 
    this._visibleReviewerReportsUpdate.next()
  }

  private getFlippedSortingDirectionForSortingOption(sortingOption: SortingOption): SortingDirection {
    if (this.sortingStatus.sortBy === sortingOption) {
      return this.sortingStatus.sortDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  private clearReportCodeSearchValue() {
    this._reportCodeSearchValue = ''
    this._reportCodeSearchValueUpdate.next()
  }

  constructor (
    private reviewerReportsService: ReviewerReportsService
  ) {
    super()
  }

  public subscribeToPaginationStatusUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._paginationStatusUpdate, callback)
  }

  public subscribeToSortingStatusUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._sortingStatusUpdate, callback)
  }

  public subscribeToReviewerReportsUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._visibleReviewerReportsUpdate, callback)
  }

  public subscribeToReportCodeSearchValueUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._reportCodeSearchValueUpdate, callback)
  }

  public subscribeToFilteringStatusUpdate(callback: () => any): CancelableSubscription {
    return this.subscribeTo(this._filteringStatusUpdate, callback)
  }

  public async getReviewerReport(shortCode: string): Promise<void> {
    const reviewerReport = await this.reviewerReportsService.getReviewerReport({ shortCode })

    if (reviewerReport === null) {
      this._totalNumberOfReportsInTheDatabase = 0
      this.updateVisibleReviewerReports([])
    }
    else {
      this._totalNumberOfReportsInTheDatabase = 1
      this.updateVisibleReviewerReports([reviewerReport])
    }

    this._paginationStatusUpdate.next()
  }

  public async getReviewerReports(): Promise<void> {

    const { sortBy, sortDirection } = this.sortingStatus
    const { currentPage, rowsPerPage } = this.paginationStatus

    const reviewerReports = await this.reviewerReportsService.getReviewerReports({
      sortBy,
      sortDirection
    }, {
      pageNumber: currentPage,
      rowsPerPage
    }, {
      filters: this._filteringStatus
    })

    // set the total number of reports and push the pagination status update 
    this._totalNumberOfReportsInTheDatabase = reviewerReports.total
  
    this._paginationStatusUpdate.next()

    this.updateVisibleReviewerReports(reviewerReports.items)
  }

  public setRowsPerPage(rowsPerPage: RowsPerPageOption): void {
    this._paginationStatus = {
      currentPage: 0,
      rowsPerPage
    }

    // push pagination status change 
    this._paginationStatusUpdate.next()

    this.clearReportCodeSearchValue()

    this.getReviewerReports()
  }

  public moveToPage(page: number): void {
    this._paginationStatus.currentPage = page

    // push pagination status change 
    this._paginationStatusUpdate.next()

    this.getReviewerReports()
  }

  public toggleSortingBy(sortBy: SortingOption) {
    this._sortingStatus = {
      sortBy,
      sortDirection: this.getFlippedSortingDirectionForSortingOption(sortBy)
    }
    
    // sorting should set the current page to 0
    this._paginationStatus.currentPage = 0

    // push sorting status update 
    this._sortingStatusUpdate.next()
    this._paginationStatusUpdate.next()
    this.clearReportCodeSearchValue()

    this.getReviewerReports()
  }

  public setReportCodeSearchValue(reportCodeSearchValue: string) {
    this._reportCodeSearchValue = reportCodeSearchValue
    this._reportCodeSearchValueUpdate.next()
  }

  public setFilteringOptions(options: Array<ReviewStatusType>) {
    this._filteringStatus = options

    this._paginationStatus.currentPage = 0

    this._filteringStatusUpdate.next()
    this._paginationStatusUpdate.next()
    this.clearReportCodeSearchValue()

    this.getReviewerReports()
  }

  public get sortingStatus(): SortingStatus {
    return this._sortingStatus
  }

  public get paginationStatus(): PaginationStatus {
    return this._paginationStatus
  }

  public get visibleReviewerReports(): ReviewerReport[] {
    return this._visibleReviewerReports
  }

  public get totalNumberOfReportsInTheDatabase(): number {
    return this._totalNumberOfReportsInTheDatabase
  }

  public get reportCodeSearchValue(): string {
    return this._reportCodeSearchValue
  }

  public get filteringStatus(): ReviewStatusType[] {
    return this._filteringStatus
  }
}

export default MainReviewerReportsManager
