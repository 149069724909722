import ServiceLocator from "../../../../shared/service-locator"
import { ReviewerReport } from "../../../models/reviewer-report.model"
import { useState, useEffect } from "react"

const reviewerReportsManager = ServiceLocator.instance.reviewerReportsManager

type ReviewerReportsTableBodyState = ReviewerReport[]

export function useReviewerReportsTableBodyController() {

  const [ visibleReviewerReports, setVisibleReviewerReports ] = useState<ReviewerReportsTableBodyState>(
    reviewerReportsManager.visibleReviewerReports
  )

  useEffect(() => {
    const subscription = reviewerReportsManager.subscribeToReviewerReportsUpdate(() => {
      setVisibleReviewerReports(reviewerReportsManager.visibleReviewerReports)
    })
    
    reviewerReportsManager.getReviewerReports()

    return () => {
      subscription.cancel()
    }
  }, [])

  return {
    visibleReviewerReports
  }
}
