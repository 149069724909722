import React, { FunctionComponent } from 'react'
import { Typography } from "@material-ui/core";
import { fontSizes } from '../../../../shared/theme/font-sizes.theme';

const SubmitDateDisplay: FunctionComponent<{
  submitDate: string
}> = ({ submitDate }) => {
  return (
    <Typography style={{ fontSize: fontSizes[14] }}>{submitDate}</Typography>
  )
}

export default SubmitDateDisplay
