import React, { FunctionComponent } from 'react'
import { Typography } from "@material-ui/core";
import { fontSizes } from '../../../../shared/theme/font-sizes.theme';

const LastUpdatedByDisplay: FunctionComponent<{
  lastUpdatedBy: string | null
}> = ({ lastUpdatedBy }) => {
  return (
    <Typography style={{ fontSize: fontSizes[14] }}>{lastUpdatedBy ?? ''}</Typography>
  )
}

export default LastUpdatedByDisplay
