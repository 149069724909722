export type FontSize = 
  '12' |
  '14' |
  '16' |
  '18' |
  '24'

export const fontSizes: Readonly<Record<FontSize, string>> = {
  '12': '0.75rem',
  '14': '0.875rem',
  '16': '1rem' ,
  '18': '1.125rem',
  '24': '1.5rem'
}
