import { Subscribable } from "../../../shared/base-classes/subscribable";
import { HttpClient } from "../../../shared/clients/http-client/http-client.interface";
import { Subject } from "rxjs";
import { FormattedSectionDetails, formatSectionDetails, SectionDetails } from "../../../shared/utils/format-section-details.util";

export class VehicleSectionDetailsService extends Subscribable {

  private _vehicleSectionDetails: SectionDetails = {
    inputFields: []
  }
  private _vehicleSectionDetailsUpdate: Subject<void> = new Subject<void>()
  
  constructor(
    private httpClient: HttpClient
  ) {
    super()
  }

  public async getVehicleSectionDetails(reviewerReportId: string) {
    const vehicleSectionDetails = await this.httpClient.get('/api/reviewer/view-vehicle-section-details', {
      query: {
        reviewerReportId
      }
    })

    this._vehicleSectionDetails = vehicleSectionDetails
    this._vehicleSectionDetailsUpdate.next()
  }

  public subscribeToVehicleSectionDetailsUpdate(callback: () => any) {
    return this.subscribeTo(this._vehicleSectionDetailsUpdate, callback)
  }

  public get vehicleSectionDetails(): FormattedSectionDetails {
    return formatSectionDetails(this._vehicleSectionDetails)
  }

}
