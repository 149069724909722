import { Subscribable } from "../../../shared/base-classes/subscribable";
import { HttpClient } from "../../../shared/clients/http-client/http-client.interface";
import { Subject } from "rxjs";
import { FormattedSectionDetails, formatSectionDetails, SectionDetails } from "../../../shared/utils/format-section-details.util";

export class DateAndLocationSectionDetailsService extends Subscribable {

  private _sectionDetails: SectionDetails = {
    inputFields: []
  }
  private _sectionDetailsUpdate: Subject<void> = new Subject<void>()
  
  constructor(
    private httpClient: HttpClient
  ) {
    super()
  }

  public async getDateAndLocationSectionDetails(reviewerReportId: string) {
    const dateAndLocationSectionDetails = await this.httpClient.get('/api/reviewer/view-date-and-location-section-details', {
      query: {
        reviewerReportId,
        sectionName: "dateAndLocation"
      }
    })

    this._sectionDetails = dateAndLocationSectionDetails
    this._sectionDetailsUpdate.next()
  }

  public subscribeToDateAndLocationSectionDetailsUpdate(callback: () => any) {
    return this.subscribeTo(this._sectionDetailsUpdate, callback)
  }

  public get dateAndLocationSectionDetails(): FormattedSectionDetails {
    return formatSectionDetails(this._sectionDetails)
  }

}
