export type ReviewStatusType = 'Unopened' | 'In progress' | 'Reviewed' | 'Submitted'
export const REVIEW_STATUS_TYPES: ReadonlyArray<ReviewStatusType> = ['Unopened', 'In progress', 'Reviewed', 'Submitted']

export class ReviewerReport {

  constructor (
    private _reviewerReportId: string,
    private _changesSinceLastUpdate: boolean,
    private _lastUpdatedBy: string|null,
    private _reviewStatus: ReviewStatusType,
    private _shortCode: string,
    private _submitDate: string,
    private _hasComment: boolean
  ) { }

  public get reviewerReportId() {
    return this._reviewerReportId
  }
  public get changesSinceLastUpdate() {
    return this._changesSinceLastUpdate
  }
  public get lastUpdatedBy() {
    return this._lastUpdatedBy
  }
  public get reviewStatus() {
    return this._reviewStatus
  }
  public get shortCode() {
    return this._shortCode
  }
  public get submitDate() {
    return this._submitDate
  }
  public get hasComments() {
    return this._hasComment
  }

}
