import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import OverviewPage from './overview/components/overview-page/overview-page'
import ServiceLocator from './shared/service-locator'
import LoginPage from './auth/components/login-page/login-page'
import ReportDetailsPage from './report-details/components/report-details-page/report-details-page'
import Profile from './profile/profile'
import AuthGuard from './auth/components/auth-guard/auth-guard'

ServiceLocator.initialize()

const App: React.FunctionComponent = () => {
  return (
    <div id="app" style={{ height: '100vh' }}>
      <Router>
        <Profile />
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/overview">
            <AuthGuard>
              <OverviewPage />
            </AuthGuard>
          </Route>
          <Route path="/report-details">
            <AuthGuard>
              <ReportDetailsPage />
            </AuthGuard>
          </Route>
          <Redirect to="/login" />
        </Switch>
      </Router>
    </div>
  )
}

export default App
