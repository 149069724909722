import { useEffect, useState } from "react"
import ServiceLocator from "../../../shared/service-locator"
import { UserModel } from "../../models/user.model"

const authService = ServiceLocator.instance.authService

export function useAuthGuardController() {

  const [ user, setUser ] = useState<UserModel|undefined>(undefined)

  useEffect(() => {
    const subscription = authService.subscribeToUserUpdated(() => {
      setUser(authService.user)
    })
    
    const authenticate = async () => {
      const user = await authService.getUser()
      if (!user) {
        window.location.href = '/'
      }
    }

    authenticate()

    return () => {
      subscription.cancel()
    }
  }, [])

  return {
    user
  }
}

