import React, { FunctionComponent } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ChangesSinceLastUpdatedIndicator from './changes-since-last-updated-indicator';
import ReviewerStatusChip from './reviewer-status-chip';
import OpenReportButton from './open-report-button/open-report-button';
import ShortCodeDisplay from './short-code-display';
import SubmitDateDisplay from './submit-date-display';
import LastUpdatedByDisplay from './last-updated-by-display';
import { ReviewerReport } from '../../../models/reviewer-report.model';
import HasCommentsDisplay from './has-comments-display';


const ReviewerReportsTableRow: FunctionComponent<{
  reviewerReport: ReviewerReport
}> = ({ reviewerReport }) => {
  return (
    <TableRow key={reviewerReport.shortCode}>
      <TableCell align="center">
        <ShortCodeDisplay shortCode={reviewerReport.shortCode} />
      </TableCell>
      <TableCell align="center">
        <SubmitDateDisplay submitDate={reviewerReport.submitDate} />
      </TableCell>
      <TableCell align="center">
        <ReviewerStatusChip status={reviewerReport.reviewStatus} />
      </TableCell>
      <TableCell align="center">
        <LastUpdatedByDisplay lastUpdatedBy={reviewerReport.lastUpdatedBy} />
      </TableCell>
      <TableCell align="center">
        <HasCommentsDisplay hasComments={reviewerReport.hasComments} />
      </TableCell>
      <TableCell align="center">
        <ChangesSinceLastUpdatedIndicator changesSinceLastUpdated={reviewerReport.changesSinceLastUpdate} />
      </TableCell>
      <TableCell align="center">
        <OpenReportButton reviewerReport={reviewerReport} />
      </TableCell>
    </TableRow>
  )
}

export default ReviewerReportsTableRow
