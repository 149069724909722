import React from 'react'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Typography } from '@material-ui/core';
import colorPalette from '../../../shared/theme/color-palette.theme';
import { useBackToOverviewButtonController } from './back-to-overview-button.controller';

const BackToOverviewButton = () => {

  const { handleLinkClick } = useBackToOverviewButtonController()
  
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      padding: '0 5%',
      background: colorPalette.tintedWhite
    }}>
      <button style={{ display: 'flex', border: 'none', background: 'none' }} onClick={handleLinkClick}>
        <KeyboardArrowLeftIcon style={{ color: colorPalette.neonPurple }} />
        <Typography style={{ fontWeight: 'bold', color: colorPalette.neonPurple }}>Back to Reports</Typography>
      </button>
    </div>
  )
}

export default BackToOverviewButton