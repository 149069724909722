import { HttpClient } from '../../shared/clients/http-client/http-client.interface'
import { UserModel } from '../models/user.model'
import { Subject } from 'rxjs'
import { Subscribable } from '../../shared/base-classes/subscribable'

export class AuthService extends Subscribable {

  private _user: UserModel | undefined = undefined
  private _userUpdated: Subject<void> = new Subject<void>()

  constructor(
    private httpClient: HttpClient
  ) {
    super()
  }

  async signIn(): Promise<void> {
    await this.httpClient.get('/auth/signIn')
  }

  async signOut(): Promise<void> {
    await this.httpClient.get('/auth/signOut')
  }

  async getUser(): Promise<UserModel | undefined> {
    try {
      const user = await this.httpClient.get('/auth/user')
      this._user = user
      this._userUpdated.next()
      return user
    } catch (error) {
      return undefined
    }
  }

  public subscribeToUserUpdated(callback: () => any) {
    return this.subscribeTo(this._userUpdated, callback)
  }

  public get user() {
    return this._user
  }
}
