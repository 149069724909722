import { HttpClient } from "./clients/http-client/http-client.interface"
import MainHttpClient from './clients/http-client/main-http-client'
import MainServiceConfig from "../config/service-config/main-service-config"
import { ReviewerReportsService } from "../overview/services/reviewer-reports-service/reviewer-reports-service.interface"
import { ReviewerReportsManager } from "../overview/managers/reviewer-reports-manager.interface"
import { ReviewerReportDetailsService } from "../report-details/services/reviewer-report-details-service/reviewer-report-details-service.interface"
import { AuthService } from "../auth/services/auth.service"
import { ServiceConfig } from "../config/service-config/service-config.interface"
import MainReviewerReportsService from "../overview/services/reviewer-reports-service/main-reviewer-reports-service"
import { MainReviewerReportDetailsService } from "../report-details/services/reviewer-report-details-service/main-reviewer-report-details-service"
import MainReviewerReportsManager from "../overview/managers/main-reviewer-reports-manager"
import { VictimSectionDetailsService } from "../report-details/services/victim-section-details-service/victim-section-details-service"
import { DateAndLocationSectionDetailsService } from "../report-details/services/date-and-location-section-details-service/date-and-location-section-details-service"
import { SectionSelectorService } from "../report-details/services/section-selector-service/section-selector-service"
import { VehicleSectionDetailsService } from "../report-details/services/vehicle-section-details-service.ts/vehicle-section-details-service"
import { EventsSectionDetailsService } from "../report-details/services/events-section-details-servce/events-section-details-service"
import { SuspectSectionDetailsService } from "../report-details/services/suspect-section-details-service/suspect-section-details-service"

class ServiceLocator {
  private static _instance: ServiceLocator

  private _reviewerReportsService: ReviewerReportsService
  private _reviewerReportsManager: ReviewerReportsManager
  private _reviewerReportDetailsService: ReviewerReportDetailsService
  private _victimSectionDetailsService: VictimSectionDetailsService
  private _dateAndLocationSectionDetailsService: DateAndLocationSectionDetailsService
  private _vehicleSectionDetailsService: VehicleSectionDetailsService
  private _suspectSectionDetailsService: SuspectSectionDetailsService
  private _eventsSectionDetailsService: EventsSectionDetailsService
  private _sectionSelectorService: SectionSelectorService

  private _authService: AuthService
  private _serviceConfig: ServiceConfig

  private constructor() {
    this._serviceConfig = new MainServiceConfig()

    this._reviewerReportsService = new MainReviewerReportsService(this.mainHttpClient)
    this._reviewerReportDetailsService = new MainReviewerReportDetailsService(this.mainHttpClient)
    this._victimSectionDetailsService = new VictimSectionDetailsService(this.mainHttpClient)
    this._dateAndLocationSectionDetailsService = new DateAndLocationSectionDetailsService(this.mainHttpClient)
    this._vehicleSectionDetailsService = new VehicleSectionDetailsService(this.mainHttpClient)
    this._suspectSectionDetailsService = new SuspectSectionDetailsService(this.mainHttpClient)
    this._eventsSectionDetailsService = new EventsSectionDetailsService(this.mainHttpClient)
    this._sectionSelectorService = new SectionSelectorService()

    this._reviewerReportsManager = new MainReviewerReportsManager(this._reviewerReportsService)
    this._authService = new AuthService(this.mainHttpClient)
  }

  public static initialize() {
    ServiceLocator._instance = new ServiceLocator()
  }

  public static get instance(): ServiceLocator {
    if (!ServiceLocator._instance) {
      ServiceLocator._instance = new ServiceLocator()
    }
    return ServiceLocator._instance
  }

  public get reviewerReportsService(): ReviewerReportsService {
    return this._reviewerReportsService
  }
  
  public get reviewerReportDetailsService(): ReviewerReportDetailsService {
    return this._reviewerReportDetailsService
  }

  public get victimSectionDetailsService(): VictimSectionDetailsService {
    return this._victimSectionDetailsService
  }
  
  public get dateAndLocationSectionDetailsService(): DateAndLocationSectionDetailsService {
    return this._dateAndLocationSectionDetailsService
  }
  
  public get vehicleSectionDetailsService(): VehicleSectionDetailsService {
    return this._vehicleSectionDetailsService
  }

  public get suspectSectionDetailsService(): SuspectSectionDetailsService {
    return this._suspectSectionDetailsService
  }

  public get eventsSectionDetailsService(): EventsSectionDetailsService {
    return this._eventsSectionDetailsService
  }

  public get sectionSelectorService(): SectionSelectorService {
    return this._sectionSelectorService
  }

  public get reviewerReportsManager(): ReviewerReportsManager {
    return this._reviewerReportsManager
  }

  public get authService(): AuthService {
    return this._authService
  }

  public get serviceConfig(): ServiceConfig {
    return this._serviceConfig
  }

  private get mainHttpClient(): HttpClient {
    return new MainHttpClient(this.serviceConfig.baseApiEndpoint, window.fetch.bind(window))
  }
}

export default ServiceLocator
