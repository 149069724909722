import React from 'react'
import colorPalette from "../theme/color-palette.theme"

const PurpleDot = () => {
  return (
    <div style={{
      width: '12px',
      height: '12px',
      borderRadius: '5px',
      background: colorPalette.neonPurple,
      margin: 'auto'
    }} />
  )
}

export default PurpleDot
