import React from 'react'
import colorPalette from '../shared/theme/color-palette.theme'
import { Button, Typography } from '@material-ui/core'
import { useProfileController } from './profile.controller'

const Profile = () => {

  const { user, handleLogout } = useProfileController()

  if (!user) {
    return null
  }

  return (
    <div role="banner" style={{
      height: '50px',
      background: colorPalette.neonPurple,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }}>
      <div style={{ padding: '0 24px' }}>
        <Typography style={{ color: 'white' }}>{user.name}</Typography>
      </div>
      <div style={{ padding: '0 24px' }}>
        <Button
          style={{
            background: 'white',
            height: '30px',
            color: colorPalette.neonPurple
          }}
          variant="outlined"
          onClick={handleLogout}
        >logout</Button>
      </div>
    </div>
  )
}

export default Profile