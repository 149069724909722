import React from 'react'
import moment from 'moment'
import AddIcon from '@material-ui/icons/Add';
import { Accordion, AccordionSummary, Typography, AccordionDetails, withStyles, Grid, TextField, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colorPalette from '../../../shared/theme/color-palette.theme';
import { fontSizes } from '../../../shared/theme/font-sizes.theme';
import { useReportDetailsCommentsController } from './report-details-comments.controller';

const StyledAccordion = withStyles({
  root: {
    borderTop: `1px solid ${colorPalette.neonPurple}`,
    borderBottom: `1px solid ${colorPalette.neonPurple}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ReportDetailsComments = () => {

  const {
    user,
    comments,
    newComment,
    handleNewCommentChange,
    handleAddCommentButtonClick
  } = useReportDetailsCommentsController()

  return (
    <div>
      <StyledAccordion square={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colorPalette.neonPurple }} />}>
          <div style={{ padding: '5px 0' }}>
            <Typography style={{ fontSize: fontSizes[18], fontWeight: 'bold' }}>
              Comments ({comments.length})
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography>User</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Date added (YYYY/MM/DD)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Note</Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: '3px', background: colorPalette.lightGrey, margin: '10px 0' }} />
            </Grid>
            <Grid item xs={3}>
              <div style={{ padding: '24px 0' }}>
                <Typography style={{ fontSize: fontSizes[14] }}>{user?.name}</Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={6}>
            <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection:'column', padding: '24px 0'}}>
              <div style={{ width: '100%'}}>
                <TextField
                  inputProps={{
                    'aria-label': "add a comment"
                  }}
                  style={{ fontSize: fontSizes[14] }}
                  fullWidth={true}
                  multiline
                  rows={4}
                  value={newComment}
                  onChange={handleNewCommentChange}
                />
              </div>
              <div style={{ width:'130px', marginTop: '8px'}}>
                <Button
                  onClick={handleAddCommentButtonClick}
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  <Typography style={{ fontSize: fontSizes[14] }}>add note</Typography>

                </Button>
              </div>
              </div>
            </Grid>
            {comments.map((comment, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={3}>
                    <div style={{ padding: '24px 0' }}>
                      <Typography style={{ fontSize: fontSizes[14] }}>{comment.addedBy}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ padding: '24px 0' }}>
                      <Typography style={{ fontSize: fontSizes[14] }}>{moment(comment.timestamp).format("YYYY/MM/DD")}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ padding: '24px 0' }}>
                      <Typography style={{ fontSize: fontSizes[14] }}>{comment.comment}</Typography>
                    </div>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  )
}

export default ReportDetailsComments
