import React, { FunctionComponent } from 'react'
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { TableFooter, TablePagination, TableRow } from '@material-ui/core';
import { useReviewerReportsTableFooterController } from './reviewer-reports-table-footer.controller';

const ReviewerReportsTableFooter: FunctionComponent = () => {

  const {
    state,
    handleRowsPerPageChange,
    handlePageChange,
    rowsPerPageOptions
  } = useReviewerReportsTableFooterController()

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={6}
          count={state.totalNumberOfReportsInTheDatabase}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  )
}

export default ReviewerReportsTableFooter
