import React from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import { InputLabel, FormControl } from '@material-ui/core';
import { useReportFilterDropdownController } from './report-filter-dropdown.controller';

const ReportFilterDropdown: React.FunctionComponent = () => {

  const {
    filteringStatus,
    reviewStatusTypes,
    handleChange
  } = useReportFilterDropdownController()

  return (
    <div style={{ width: '270px', marginLeft: 'auto' }}>
    <FormControl fullWidth={true} >
      <InputLabel variant="outlined">Filter by</InputLabel>
      <Select
        aria-label="filter by review status"
        variant="outlined"
        value={filteringStatus}
        onChange={handleChange}
        fullWidth={true}
        multiple
        IconComponent={FilterListIcon}
        label="Filter by"
      >
        {reviewStatusTypes.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </div>
  )
}

export default ReportFilterDropdown
