import React, { FunctionComponent } from 'react'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import colorPalette from '../../../../shared/theme/color-palette.theme';

const HasCommentsDisplay: FunctionComponent<{ hasComments: boolean }> = ({ hasComments }) => {
  if (hasComments) {
    return (
      <ChatBubbleOutlineIcon aria-label="comment icon" style={{ color: colorPalette.neonPurple }} />
    )
  }
  return null
}

export default HasCommentsDisplay