import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useReviewerReportsTableHeadController } from './reviewer-reports-table-head.controller';
import { TableSortLabel } from '@material-ui/core';

const ReviewerReportsTableHead = () => {
  const {
    sortingStatus,
    handleSortingOptionToggle
  } = useReviewerReportsTableHeadController()

  return (
    <TableHead>
      <TableRow style={{ verticalAlign: 'top' }}>
        <TableCell align="center">Report Code</TableCell>
        <TableCell align="center">
          <TableSortLabel
            aria-label={sortingStatus.sortDirection === 'asc' ? "order by ascending submit date" : "order by descending submit date"}
            active={sortingStatus.sortBy === 'submitDate'}
            direction={sortingStatus.sortDirection}
            onClick={() => handleSortingOptionToggle('submitDate')}
          >
            Date Received (YYYY/MM/DD)
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            aria-label={sortingStatus.sortDirection === 'asc' ? "order by ascending reviewer status" : "order by descending reviewer status"}
            active={sortingStatus.sortBy === 'reviewStatus'}
            direction={sortingStatus.sortDirection}
            onClick={() => handleSortingOptionToggle('reviewStatus')}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            aria-label={sortingStatus.sortDirection === 'asc' ? "order by ascending last updated by" : "order by descending last updated by"}
            active={sortingStatus.sortBy === 'lastUpdatedBy'}
            direction={sortingStatus.sortDirection}
            onClick={() => handleSortingOptionToggle('lastUpdatedBy')}
          >
            Last Updated by
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            aria-label={sortingStatus.sortDirection === 'asc' ? "order reports with comments on the bottom" : "order reports with comments on the top"}
            active={sortingStatus.sortBy === 'hasComments'}
            direction={sortingStatus.sortDirection}
            onClick={() => handleSortingOptionToggle('hasComments')}
          >
            Comments
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            aria-label={sortingStatus.sortDirection === 'asc' ? "order reports that have changes on the bottom" : "order reports that have changes on the top"}
            active={sortingStatus.sortBy === 'changesSinceLastUpdate'}
            direction={sortingStatus.sortDirection}
            onClick={() => handleSortingOptionToggle('changesSinceLastUpdate')}
          >
            Changes since Last Update
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">Link to Report</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ReviewerReportsTableHead
