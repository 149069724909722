import qs from 'query-string'

abstract class BaseHttpClient {
  protected formatQueryString(query?: {[key: string]: string}): string {
    if (!query) return ''

    const dashedKeyQueryObj: {[key: string]: string} = {}

    Object.keys(query).forEach(key => {
      dashedKeyQueryObj[key.replace(/[A-Z]/g, m => "-" + m.toLowerCase())] = query[key]
    })

    return `?${qs.stringify(dashedKeyQueryObj)}`
  }}

export default BaseHttpClient
