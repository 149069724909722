import React from 'react'
import Page from '../../../shared/components/page'
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'
import ServiceLocator from '../../../shared/service-locator'

const serviceLocator = ServiceLocator.instance
const serviceConfig = serviceLocator.serviceConfig

const LoginPage = () => {
  const login = () => { window.location.replace(`${serviceConfig.baseApiEndpoint}/auth/signIn`) }
  const query = new URLSearchParams(window.location.search)
  const errorStatus = query.get('status')

  return (
    <Page>
      { errorStatus &&
        <Alert severity="error">
          Error - You do not have permission to use this application
        </Alert>
      }
      <h1 style={{ textAlign: 'center' }}>Reviewer App</h1>
      <Button color="primary" onClick={login} style={{ display: 'flex', margin: 'auto' }}>Login</Button>
    </Page>
  )
}

export default LoginPage
