import React from 'react'
import TextField from '@material-ui/core/TextField';
import { useReportCodeSearchBoxController } from './report-code-search-box.controller';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const ReportCodeSeaerchBox: React.FunctionComponent = () => {

  const {
    reportCode,
    handleReportCodeChange,
    handleSearchReport
  } = useReportCodeSearchBoxController()

  return (
    <div style={{ width: '270px' }}>
      <TextField
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            handleSearchReport()
          }
        }}
        value={reportCode}
        onChange={handleReportCodeChange}
        onBlur={handleSearchReport}
        fullWidth={true}
        size="medium"
        id="outlined-basic"
        label="Search by Report Code"
        variant="outlined"
        placeholder="AAA-AAA-AAA"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />
    </div>
  )
}

export default ReportCodeSeaerchBox

