import ServiceLocator from "../../../shared/service-locator"
import { useState, useEffect } from "react"
import { StatusUpdateHistory } from "../../services/reviewer-report-details-service/reviewer-report-details-service.interface"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService

export function useReportDetailsStatusHistoryController() {

  const [ statusUpdateHistory, setStatusUpdateHistory ] = useState<StatusUpdateHistory>(reviewerReportDetailsService.statusUpdateHistory)
  
  useEffect(() => {
    const subscription = reviewerReportDetailsService.subscribeToStatusUpdateHistoryUpdate(() => {
      setStatusUpdateHistory(reviewerReportDetailsService.statusUpdateHistory)
    })

    reviewerReportDetailsService.getStatusUpdateHistory()

    return () => {
      subscription.cancel()
    }
  }, [])

  return {
    statusUpdateHistory,
    submitDate: reviewerReportDetailsService.reviewerReportDetailsSummary?.submitDate
  }
}
