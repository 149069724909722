import { useState, useEffect } from "react"
import ServiceLocator from "../../../shared/service-locator"
import { Comments } from "../../services/reviewer-report-details-service/reviewer-report-details-service.interface"
import { UserModel } from "../../../auth/models/user.model"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService
const authService = ServiceLocator.instance.authService

export function useReportDetailsCommentsController() {

  const [ comments, setComments ] = useState<Comments>(reviewerReportDetailsService.comments)
  const [ newComment, setNewComment ] = useState<string>('')
  const [ user, setUser ] = useState<UserModel|undefined>()

  useEffect(() => {
    const userSubscription = authService.subscribeToUserUpdated(() => {
      setUser(authService.user)
    })
    
    const subscription = reviewerReportDetailsService.subscribeToCommentsUpdate(() => {
      setComments(reviewerReportDetailsService.comments)
    })

    authService.getUser()

    reviewerReportDetailsService.getComments()

    return () => {
      subscription.cancel()
      userSubscription.cancel()
    }
  }, [])

  const handleNewCommentChange = (e: any) => {
    const value = e.target.value
    setNewComment(value)
  }

  const handleAddCommentButtonClick = () => {
    reviewerReportDetailsService.addComment(newComment)
    setNewComment('')
  }

  return {
    user,
    comments,
    newComment,
    handleNewCommentChange,
    handleAddCommentButtonClick
  }
}