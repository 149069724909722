import React from 'react'
import { CircularProgress } from '@material-ui/core'

type FullScreenLoaderProps = {
  loading: boolean
}

function FullScreenLoader({ loading }: FullScreenLoaderProps): JSX.Element {
  return (
    <div style={{
      position: 'absolute',
      inset: '0px',
      background: 'rgba(255,255,255,0.8)',
      zIndex: 1,
      display: loading ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress size="5rem" />
    </div>
  )
}

export default FullScreenLoader
