import { Question } from "../../../../shared/interfaces/question.interface"

export const suspectSectionQuestions: Array<Question> = [
  {
    inputFieldId: 1,
    content: 'Did you know this person?',
  },
  {
    inputFieldId: 2,
    content: 'Would you be comfortable providing their name(s)? / Did the suspect(s) give you their name(s)?'
  },
  {
    inputFieldId: 3,
    content: 'How did the suspect(s) know you?'
  },
  {
    inputFieldId: 4,
    content: 'Approximate age'
  },
  {
    inputFieldId: 5,
    content: 'Approximate height'
  },
  {
    inputFieldId: 6,
    content: 'Race appearance'
  },
  {
    inputFieldId: 7,
    content: 'Skin colour'
  },
  {
    inputFieldId: 8,
    content: 'Hair colour'
  },
  {
    inputFieldId: 9,
    content: 'Gender'
  },
  {
    inputFieldId: 10,
    content: 'How would you describe their build? (eg. stocky)'
  },
  {
    inputFieldId: 11,
    content: 'If you were able to get a look, would you be able to describe any distinct features about the suspect? (eg. scars / tattoos / facial hair / odours)'
  },
  {
    inputFieldId: 12,
    content: 'If this suspect spoke to you, do you recall any distinct accents or language they used?'
  },
  {
    inputFieldId: 13,
    content: 'Were there any distinct words or phrases this person used?'
  },
]
