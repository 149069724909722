import { useHistory } from "react-router-dom"

export function useBackToOverviewButtonController() {

  const history = useHistory()

  const handleLinkClick = () => {
    history.push('/overview')
  }
  
  return {
    handleLinkClick
  }
}
