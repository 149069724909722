import React, { FunctionComponent } from 'react'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import colorPalette from '../../../../../shared/theme/color-palette.theme';
import { useOpenReportButtonController } from './open-report-button.controller';
import { ReviewerReport } from '../../../../models/reviewer-report.model';

const StyledButton = withStyles({
  root: {
    color: colorPalette.neonPurple
  }
})(Button)

const OpenReportButton: FunctionComponent<{
  reviewerReport: ReviewerReport
}> = ({ reviewerReport }) => {

  const { handleClick } = useOpenReportButtonController(reviewerReport)
  
  return (
    <StyledButton onClick={handleClick} variant="outlined">Open Report</StyledButton>
  )
}

export default OpenReportButton
