import { useState, useEffect } from "react"
import ServiceLocator from "../../../../shared/service-locator"
import { ROWS_PER_PAGE_OPTIONS } from "../../../managers/main-reviewer-reports-manager"
import { RowsPerPageOption } from "../../../managers/reviewer-reports-manager.interface"

const reviewerReportsManager = ServiceLocator.instance.reviewerReportsManager

interface ReviewerReportsTableFooterState {
  rowsPerPage: RowsPerPageOption;
  page: number;
  totalNumberOfReportsInTheDatabase: number;
}

export function useReviewerReportsTableFooterController() {

  const [ reviewerReportsTableFooterState, setReviewerReportsTableFooterState ] = useState<ReviewerReportsTableFooterState>({
    rowsPerPage: reviewerReportsManager.paginationStatus.rowsPerPage,
    page: reviewerReportsManager.paginationStatus.currentPage,
    totalNumberOfReportsInTheDatabase: reviewerReportsManager.totalNumberOfReportsInTheDatabase
  })

  useEffect(() => {
    const subscription = reviewerReportsManager.subscribeToPaginationStatusUpdate(() => {
      setReviewerReportsTableFooterState({
        rowsPerPage: reviewerReportsManager.paginationStatus.rowsPerPage,
        page: reviewerReportsManager.paginationStatus.currentPage,
        totalNumberOfReportsInTheDatabase: reviewerReportsManager.totalNumberOfReportsInTheDatabase
      })
    })

    return () => {
      subscription.cancel()
    }
  }, [])

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const rowsPerPage = parseInt(e.target.value, 10)
    reviewerReportsManager.setRowsPerPage(rowsPerPage as RowsPerPageOption)
  }

  const handlePageChange = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    reviewerReportsManager.moveToPage(page)
  }

  return {
    state: reviewerReportsTableFooterState,
    handleRowsPerPageChange,
    handlePageChange,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS
  }
}
