import React from 'react'
import GridHead from '../../../../shared/components/report-details-grid-head'
import ReportDetailsQuestions from '../../../../shared/components/report-details-questions'
import { useEventsSectionDetailsController } from './events-section-details.controller'
import { eventsSectionQuestions as questions } from './events-section-details.content'

const EventsSectionDetails = () => {

  const {
    sectionDetails
  } = useEventsSectionDetailsController()

  return (
    <div>
      <GridHead />
      <ReportDetailsQuestions sectionDetails={sectionDetails} questions={questions} />
    </div>
  )
}

export default EventsSectionDetails
