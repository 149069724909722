import ServiceLocator from "../shared/service-locator"
import { useState, useEffect } from "react"
import { UserModel } from "../auth/models/user.model"

const authService = ServiceLocator.instance.authService

export function useProfileController() {
  const [user, setUser] = useState<UserModel | undefined>(authService.user)

  useEffect(() => {
    const subscription = authService.subscribeToUserUpdated(() => {
      setUser(authService.user)
    })

    return () => {
      subscription.cancel()
    }
  }, [])

  const handleLogout = (async () => {
    try {
      await authService.signOut()
    } catch (error) {
    }
    finally {
      window.location.replace('/')
    }
  })

  return {
    user,
    handleLogout
  }
}
