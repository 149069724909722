import { SectionId } from "../../shared/types/section-id.type"
import { ReviewStatusType } from "../../overview/models/reviewer-report.model"

export class ReviewerReportDetailsSummary {

  constructor(
    private _reviewerReportId: string,
    private _shortCode: string,
    private _submitDate: string,
    private _reviewStatus: ReviewStatusType,
    private _lastUpdatedBy: string,
    private _updatedOn: string,
    private _sections: SectionId[],
    private _purgeDate?: string
  ) { }

  public get reviewerReportId() { return this._reviewerReportId }
  public get shortCode() { return this._shortCode }
  public get submitDate() { return this._submitDate }
  public get reviewerStatus() { return this._reviewStatus }
  public get lastUpdatedBy() { return this._lastUpdatedBy }
  public get updatedOn() { return this._updatedOn }
  public get sections() { return this._sections }
  public get purgeDate() { return this._purgeDate }
}
