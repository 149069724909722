import moment from 'moment'
import { ReviewerReportsService, PaginationOptions, FilteringOptions, getReviewerReportsArgs } from "./reviewer-reports-service.interface"
import { HttpClient } from "../../../shared/clients/http-client/http-client.interface"
import { GetReviewerReportsResponseType, ReviewerReportData } from "./response-types/view-reviewer-reports.response-type"
import { ReviewerReport, ReviewStatusType } from "../../models/reviewer-report.model"
import { SortingOptions } from './reviewer-reports-service.interface'

class MainReviewerReportsService implements ReviewerReportsService {
  constructor(
    private httpClient: HttpClient
  ) { }

  // FIXME: fix this
  public async getReviewerReport({ shortCode, reviewerReportId }: getReviewerReportsArgs): Promise<ReviewerReport|null> {

    const query: any = {}

    if (shortCode) {
      query.shortCode = shortCode
    }
    else if (reviewerReportId) {
      query.reviewerReportId = reviewerReportId
    }

    try {
      const reviewerReportData: ReviewerReportData = await this.httpClient.get('/api/reviewer/view-report', {
        query
      })
      return new ReviewerReport(
        reviewerReportData.reviewer_report_id,
        reviewerReportData.changes_since_last_update,
        reviewerReportData.last_updated_by,
        reviewerReportData.review_status as ReviewStatusType,
        reviewerReportData.short_code,
        moment(reviewerReportData.submit_date).format("YYYY/MM/DD"),
        reviewerReportData.has_comments
      )
    } catch (e) {
      return null
    }
  }

  public async getReviewerReports(
    sortingOptions?: SortingOptions,
    paginationOptions?: PaginationOptions,
    filteringOptions?: FilteringOptions): Promise<{
    items: ReviewerReport[]
    skip: number;
    take: number;
    total: number;
  }> {
    const reviewerReportsData: GetReviewerReportsResponseType = await this.httpClient.get('/api/reviewer/view-reports', {
      query: {
        ...sortingOptions,
        ...paginationOptions,
        ...filteringOptions
      }
    })
    return {
      items: this.createReviewerReportsDomainModel(reviewerReportsData),
      skip: reviewerReportsData.skip,
      take: reviewerReportsData.take,
      total: reviewerReportsData.total,
    }
  }

  private createReviewerReportsDomainModel(reviewerReportsData: GetReviewerReportsResponseType): ReviewerReport[] {
    return reviewerReportsData.items.map((reviewerReportData: ReviewerReportData) => {
      return new ReviewerReport(
        reviewerReportData.reviewer_report_id,
        reviewerReportData.changes_since_last_update,
        reviewerReportData.last_updated_by,
        reviewerReportData.review_status as ReviewStatusType,
        reviewerReportData.short_code,
        moment(reviewerReportData.submit_date).format("YYYY/MM/DD"),
        reviewerReportData.has_comments
      )
    })
  }
}

export default MainReviewerReportsService
