import { Question } from "../../../../shared/interfaces/question.interface"

export const victimSectionQuestions: Array<Question> = [
  {
    inputFieldId: 1,
    content: 'What was the age of the victim/survivor at the time of the offense?',
  },
  {
    inputFieldId: 2,
    content: 'What gender did the victim/survivor identify as at the time of the offense?'
  },
  {
    inputFieldId: 3,
    content: 'What race does the victim/survivor identify as?'
  }
]
