export type Color =
  'neonPurple' |
  'tintedWhite' |
  'lightGrey' |
  'grey' |
  'black' |
  'white' |
  'none'

export const colorPalette: Readonly<Record<Color, string>> = {
  neonPurple: '#6200EE',
  tintedWhite: '#F5F5F5',
  lightGrey: '#BBBBBB',
  grey: '#676767',
  black: '#000000',
  white: '#FFFFFF',
  none: 'none'
}

export default colorPalette
