import React, { FunctionComponent } from 'react'
import TableBody from '@material-ui/core/TableBody';
import ReviewerReportsTableRow from './reviewer-reports-table-row';
import { useReviewerReportsTableBodyController } from './reviewer-reports-table-body.controller';

const ReviewerReportsTableBody: FunctionComponent = () => {

  const { visibleReviewerReports } = useReviewerReportsTableBodyController()

  return (
    <TableBody>
      {visibleReviewerReports.map((reviewerReport) => (
        <ReviewerReportsTableRow key={reviewerReport.shortCode} reviewerReport={reviewerReport} />
      ))}
    </TableBody>
  )
}

export default ReviewerReportsTableBody
