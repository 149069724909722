import ServiceLocator from "../../../../shared/service-locator"
import { useEffect, useState } from "react"
import { FormattedSectionDetails } from "../../../../shared/utils/format-section-details.util"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService
const eventsSectionDetailsService = ServiceLocator.instance.eventsSectionDetailsService

export function useEventsSectionDetailsController() {

  const [ sectionDetails, setSectionDetails ] = useState<FormattedSectionDetails>(eventsSectionDetailsService.eventsSectionDetails)

  useEffect(() => {

    const reviewerReportId = reviewerReportDetailsService.targetReviewerReportId

    const reviewerStatusUpdateSubscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      if (reviewerReportId) {
        eventsSectionDetailsService.getEventsSectionDetails(reviewerReportId)
      }
    })

    const subscription = eventsSectionDetailsService.subscribeToEventsSectionDetailsUpdate(() => {
      const eventsSectionDetails = eventsSectionDetailsService.eventsSectionDetails
      setSectionDetails(eventsSectionDetails)
    })

    if (reviewerReportId) {
      eventsSectionDetailsService.getEventsSectionDetails(reviewerReportId)
    }

    return () => {
      subscription.cancel()
      reviewerStatusUpdateSubscription.cancel()
    }
  }, [])

  return {
    sectionDetails
  }
}
