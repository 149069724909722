import React from 'react'
import StatusHistory from '../report-details-status-history/report-details-status-history'
import ReportContent from '../report-details-report-content/report-details-report-content'
import Summary from '../report-details-summary/report-details-summary'
import BackToOverviewButton from '../back-to-overview-button/back-to-overview-button';
import { useReportDetailsPageController } from './report-details-page.controller'
import ReviewerComments from '../report-details-comments/report-details-comments';
import FullScreenLoader from '../../../shared/components/full-screen-loader'
import { useReportDetailsSummaryController } from '../report-details-summary/report-details-summary.controller'

const ReportDetailsPage = () => {

  const { hasTargetReportCode } = useReportDetailsPageController()

  if (!hasTargetReportCode) {
    return null
  }

  return (
    <div role="main">
      <div>
        <BackToOverviewButton />
      </div>
      <div style={{ width: '90%', margin: '0 auto', paddingTop: '50px' }}>
        <Summary />
        <StatusHistory />
        <ReviewerComments />
        <ReportContent />
      </div>
    </div>
  )
}

export default ReportDetailsPage
