import React, { FunctionComponent } from 'react'
import colorPalette from '../../../../shared/theme/color-palette.theme'

const ChangesSinceLastUpdatedIndicator: FunctionComponent<{
  changesSinceLastUpdated: boolean
}> = ({ changesSinceLastUpdated }) => {
  return changesSinceLastUpdated === true ? (
    <div aria-label="changes since last updated indicator" style={{
      width: '12px',
      height: '12px',
      borderRadius: '5px',
      background: colorPalette.neonPurple,
      margin: 'auto'
    }} />
  ) : null
}

export default ChangesSinceLastUpdatedIndicator
