import React, { FunctionComponent } from 'react'
import { Typography } from '@material-ui/core'
import { fontSizes } from '../theme/font-sizes.theme'
import colorPalette from '../theme/color-palette.theme'

const DetailsContentText: FunctionComponent<{
  isNew: boolean;
}> = ({ isNew, children }) => {
  return (
    <Typography style={{
      fontSize: fontSizes[14],
      color: isNew ? colorPalette.neonPurple : colorPalette.grey
    }}>
      {children}
    </Typography>
  )
}

export default DetailsContentText
