import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import colorPalette from '../theme/color-palette.theme'

const GridHead = () => {
  return (
    <div style={{ margin: '24px 0' }}>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Typography style={{ color: colorPalette.grey }}>Question</Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7}>
          <Grid container spacing={0}>
            <Grid item xs={7}>
              <Typography style={{ color: colorPalette.grey }}>Answer</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <Typography style={{ color: colorPalette.grey }}>Timestamp</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default GridHead
