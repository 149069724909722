import { Subscribable } from "../../../shared/base-classes/subscribable";
import { HttpClient } from "../../../shared/clients/http-client/http-client.interface";
import { Subject } from "rxjs";
import { SuspectSectionDetails, FormattedSuspectSectionDetails, formatSuspectSectionDetails } from "../../../shared/utils/format-section-details.util";

export class SuspectSectionDetailsService extends Subscribable {

  private _suspectSectionDetails: SuspectSectionDetails = {
    suspects: []
  }
  private _suspectSectionDetailsUpdate: Subject<void> = new Subject<void>()
  
  constructor(
    private httpClient: HttpClient
  ) {
    super()
  }

  private booleanMapper(stringBoolean: string) {
    if (stringBoolean === "true") {
      return "Yes"
    }
    if (stringBoolean === "false") {
      return "No"
    }
    else {
      return ""
    }
  }

  private formatSuspectSectionDetails(details: any) {
    return {
      ...details,
      suspects: details.suspects.map((suspect: any) => {
        return {
          ...suspect,
          inputFields: suspect.inputFields.map((inputField: any) => {
            return {
              ...inputField,
              fieldValues: inputField.fieldValues.map((fieldValue: any) => {
                return {
                  ...fieldValue,
                  value: inputField.fieldId === 1 ? this.booleanMapper(fieldValue.value) : fieldValue.value
                }
              })
            }
          })
        }
      })
    }
  }

  public async getSuspectSectionDetails(reviewerReportId: string) {
    const suspectSectionDetails = await this.httpClient.get('/api/reviewer/view-suspect-section-details', {
      query: {
        reviewerReportId
      }
    })

    console.log({ suspectSectionDetails })

    console.log({ formatted: this.formatSuspectSectionDetails(suspectSectionDetails) })

    this._suspectSectionDetails = this.formatSuspectSectionDetails(suspectSectionDetails)
    this._suspectSectionDetailsUpdate.next()
  }

  public subscribeToSuspectSectionDetailsUpdate(callback: () => any) {
    return this.subscribeTo(this._suspectSectionDetailsUpdate, callback)
  }

  public get suspectSectionDetails(): FormattedSuspectSectionDetails {
    return formatSuspectSectionDetails(this._suspectSectionDetails)
  }

}
