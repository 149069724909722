import React, { FunctionComponent } from 'react'
import { Typography } from "@material-ui/core";
import { formatShortCodeForDisplay } from '../../../../shared/utils/format-short-code-for-display.util';
import { fontSizes } from '../../../../shared/theme/font-sizes.theme';

const ShortCodeDisplay: FunctionComponent<{
  shortCode: string
}> = ({ shortCode }) => {
  return (
    <Typography style={{ fontSize: fontSizes[14], fontWeight: 500 }}>{formatShortCodeForDisplay(shortCode)}</Typography>
  )
}

export default ShortCodeDisplay
