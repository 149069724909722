import { Subscribable } from "../../../shared/base-classes/subscribable";
import { HttpClient } from "../../../shared/clients/http-client/http-client.interface";
import { Subject } from "rxjs";
import { FormattedSectionDetails, formatSectionDetails, SectionDetails } from "../../../shared/utils/format-section-details.util";

export class EventsSectionDetailsService extends Subscribable {

  private _eventsSectionDetails: SectionDetails = {
    inputFields: []
  }
  private _eventsSectionDetailsUpdate: Subject<void> = new Subject<void>()
  
  constructor(
    private httpClient: HttpClient
  ) {
    super()
  }

  public async getEventsSectionDetails(reviewerReportId: string) {
    const eventsSectionDetails = await this.httpClient.get('/api/reviewer/view-sequence-of-events-section-details', {
      query: {
        reviewerReportId
      }
    })

    this._eventsSectionDetails = eventsSectionDetails
    this._eventsSectionDetailsUpdate.next()
  }

  public subscribeToEventsSectionDetailsUpdate(callback: () => any) {
    return this.subscribeTo(this._eventsSectionDetailsUpdate, callback)
  }

  public get eventsSectionDetails(): FormattedSectionDetails {
    return formatSectionDetails(this._eventsSectionDetails)
  }

}
