import { Subscribable } from "../../../shared/base-classes/subscribable";
import { HttpClient } from "../../../shared/clients/http-client/http-client.interface";
import { Subject } from "rxjs";
import { FormattedSectionDetails, formatSectionDetails, SectionDetails } from "../../../shared/utils/format-section-details.util";

export class VictimSectionDetailsService extends Subscribable {

  private _victimSectionDetails: SectionDetails = {
    inputFields: []
  }
  private _victimSectionDetailsUpdate: Subject<void> = new Subject<void>()
  
  constructor(
    private httpClient: HttpClient
  ) {
    super()
  }

  public async getVictimSectionDetails(reviewerReportId: string) {
    const victimSectionDetails: SectionDetails = await this.httpClient.get('/api/reviewer/view-victim-section-details', {
      query: {
        reviewerReportId,
        sectionName: "victim"
      }
    })

    this._victimSectionDetails = victimSectionDetails
    this._victimSectionDetailsUpdate.next()
  }

  public subscribeToVictimSectionDetailsUpdate(callback: () => any) {
    return this.subscribeTo(this._victimSectionDetailsUpdate, callback)
  }

  public get victimSectionDetails(): FormattedSectionDetails {
    return formatSectionDetails(this._victimSectionDetails)
  }

}
