import { useState, useEffect } from "react"
import ServiceLocator from "../../../../shared/service-locator"
import { SortingOption } from "../../../services/reviewer-reports-service/reviewer-reports-service.interface"
import { SortingStatus } from "../../../managers/reviewer-reports-manager.interface"

const initialSortingStatus: SortingStatus = {
  sortBy: 'submitDate',
  sortDirection: 'desc'
}

const reviewerReportsManager = ServiceLocator.instance.reviewerReportsManager

export function useReviewerReportsTableHeadController() {
  const [ sortingStatus, setSortingStatus ] = useState<SortingStatus>(initialSortingStatus)

  useEffect(() => {
    const subscription = reviewerReportsManager.subscribeToSortingStatusUpdate(() => {
      setSortingStatus(reviewerReportsManager.sortingStatus)
    })

    return () => {
      subscription.cancel()
    }
  }, [])

  const handleSortingOptionToggle = (sortingOption: SortingOption) => {
    reviewerReportsManager.toggleSortingBy(sortingOption)
  }
  
  return {
    sortingStatus,
    handleSortingOptionToggle 
  }
}
