import ServiceLocator from "../../../../shared/service-locator"
import { useEffect, useState } from "react"
import { FormattedSectionDetails } from "../../../../shared/utils/format-section-details.util"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService
const dateAndLocationSectionDetailsService = ServiceLocator.instance.dateAndLocationSectionDetailsService

export function useDateAndLocationSectionDetailsController() {

  const [ sectionDetails, setSectionDetails ] = useState<FormattedSectionDetails>(dateAndLocationSectionDetailsService.dateAndLocationSectionDetails)

  useEffect(() => {

    const reviewerReportId = reviewerReportDetailsService.targetReviewerReportId

    const reviewerStatusUpdateSubscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      if (reviewerReportId) {
        dateAndLocationSectionDetailsService.getDateAndLocationSectionDetails(reviewerReportId)
      }
    })

    const subscription = dateAndLocationSectionDetailsService.subscribeToDateAndLocationSectionDetailsUpdate(() => {
      const sectionDetails = dateAndLocationSectionDetailsService.dateAndLocationSectionDetails
      setSectionDetails(sectionDetails)
    })

    if (reviewerReportId) {
      dateAndLocationSectionDetailsService.getDateAndLocationSectionDetails(reviewerReportId)
    }

    return () => {
      subscription.cancel()
      reviewerStatusUpdateSubscription.cancel()
    }
  }, [])

  return {
    sectionDetails
  }
}
