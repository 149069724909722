interface FieldValue {
  timestamp: string;
  value: string;
  isNew: boolean;
}

export interface SectionDetails {
  inputFields: Array<{
    fieldId: number;
    fieldValues: Array<FieldValue>
  }>
}

export interface SuspectDetail extends SectionDetails {
  suspectId: number;
}

export interface SuspectSectionDetails {
  suspects: Array<SuspectDetail>
}

export interface FormattedSectionDetails {
  [field: number]: {
    fieldValues: Array<FieldValue>
  }
}

export interface FormattedSuspectSectionDetails {
  [suspectId: number]: FormattedSectionDetails
}

export function formatSectionDetails(sectionDetails: SectionDetails) {
  const formattedObj: FormattedSectionDetails = {}

  sectionDetails.inputFields.forEach((inputField) => {
    formattedObj[inputField.fieldId] = {
      fieldValues: inputField.fieldValues
    }
  })

  return formattedObj
}

export function formatSuspectSectionDetails(suspectSectionDetails: SuspectSectionDetails) {
  const formattedObj: FormattedSuspectSectionDetails = {}

  suspectSectionDetails.suspects.forEach((suspect) => {
    const obj: FormattedSectionDetails = {}
    suspect.inputFields.forEach((inputField) => {
      obj[inputField.fieldId] = {
        fieldValues: inputField.fieldValues 
      }
    })
    formattedObj[suspect.suspectId] = obj
  })

  return formattedObj
}