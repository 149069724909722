import React, { FunctionComponent } from 'react'
import { Typography } from '@material-ui/core'
import { fontSizes } from '../../../shared/theme/font-sizes.theme'

const ReportCodeDisplay: FunctionComponent<{
  reportCode: string
}> = ({ reportCode }) => {
  return (
    <div>
      <h1 style={{ margin: 0 }}>
        <Typography>Report</Typography>
        <Typography style={{
          fontSize: fontSizes[24],
          fontWeight: 'bold'
        }}>{reportCode}</Typography>
      </h1>
    </div>
  )
}

export default ReportCodeDisplay
