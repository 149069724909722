import ServiceLocator from "../../../../shared/service-locator"
import { useEffect, useState } from "react"
import { FormattedSectionDetails } from "../../../../shared/utils/format-section-details.util"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService
const victimSectionDetailsService = ServiceLocator.instance.victimSectionDetailsService

export function useVictimSectionDetailsController() {

  const [ sectionDetails, setSectionDetails ] = useState<FormattedSectionDetails>(victimSectionDetailsService.victimSectionDetails)

  useEffect(() => {

    const reviewerReportId = reviewerReportDetailsService.targetReviewerReportId

    const reviewerStatusUpdateSubscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      if (reviewerReportId) {
        victimSectionDetailsService.getVictimSectionDetails(reviewerReportId)
      }
    })

    const subscription = victimSectionDetailsService.subscribeToVictimSectionDetailsUpdate(() => {
      const victimSectionDetails = victimSectionDetailsService.victimSectionDetails
      setSectionDetails(victimSectionDetails)
    })


    if (reviewerReportId) {
      victimSectionDetailsService.getVictimSectionDetails(reviewerReportId)
    }

    return () => {
      subscription.cancel()
      reviewerStatusUpdateSubscription.cancel()
    }
  }, [])

  return {
    sectionDetails
  }
}
