import React, { FunctionComponent } from 'react'
import { Tabs, Tab, withStyles } from '@material-ui/core';
import colorPalette from '../../../../shared/theme/color-palette.theme';
import { useReportDetailsSectionSelectorController } from './report-details-section-selector.controller';

const StyledTabs = withStyles({
  root: {
    borderBottom: `1px solid ${colorPalette.lightGrey}`,
  },
  indicator: {
    backgroundColor: colorPalette.neonPurple
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    '&$selected': {
      color: colorPalette.neonPurple
    },
  },
  selected: {
    color: 'red'
  }
})(Tab);

const ReportDetailsSectionSelector: FunctionComponent = () => {
  const {
    sections,
    selectedSection,
    handleSelectionChange
  } = useReportDetailsSectionSelectorController()

  if (!sections || !selectedSection) {
    return null
  }

  return (
      <StyledTabs
        value={selectedSection}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleSelectionChange}
      >
        { sections?.includes("victim") ? <StyledTab label="Victim" value="victim" /> : null }
        { sections?.includes("dateLocation") ? <StyledTab label="Date and Location" value="dateLocation" /> : null }
        { sections?.includes("vehicle") ? <StyledTab label="Vehicle" value="vehicle" /> : null }
        { sections?.includes("suspect") ? <StyledTab label="Suspect" value="suspect" /> : null }
        { sections?.includes("events") ? <StyledTab label="Events" value="events" /> : null }
      </StyledTabs>
  );
}

export default ReportDetailsSectionSelector