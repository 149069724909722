import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CancelableSubscription } from "../interfaces/cancelable-subscription.interface";

export abstract class Subscribable {

  private createCancelableSubscription(): CancelableSubscription {
    const cancelableSubscription = new Subject<void>();
    return {
      subscription: cancelableSubscription,
      cancel: () => {
        cancelableSubscription.next()
        cancelableSubscription.complete()
      }
    }
  }

  protected subscribeTo(subject: Subject<void>, callback: () => any): CancelableSubscription {
    const cancelableSubscription = this.createCancelableSubscription()

    subject
      .asObservable()
      .pipe(takeUntil(cancelableSubscription.subscription))
      .subscribe(() => {
        callback()
      })
    
    return cancelableSubscription
  }
}
