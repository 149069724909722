import ServiceLocator from "../../../../shared/service-locator"
import { useEffect, useState } from "react"
import { FormattedSectionDetails } from "../../../../shared/utils/format-section-details.util"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService
const vehicleSectionDetailsService = ServiceLocator.instance.vehicleSectionDetailsService

export function useVehicleSectionDetailsController() {

  const [ sectionDetails, setSectionDetails ] = useState<FormattedSectionDetails>(vehicleSectionDetailsService.vehicleSectionDetails)

  useEffect(() => {

    const reviewerReportId = reviewerReportDetailsService.targetReviewerReportId

    const reviewerStatusUpdateSubscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      if (reviewerReportId) {
        vehicleSectionDetailsService.getVehicleSectionDetails(reviewerReportId)
      }
    })

    const subscription = vehicleSectionDetailsService.subscribeToVehicleSectionDetailsUpdate(() => {
      const vehicleSectionDetails = vehicleSectionDetailsService.vehicleSectionDetails
      setSectionDetails(vehicleSectionDetails)
    })

    if (reviewerReportId) {
      vehicleSectionDetailsService.getVehicleSectionDetails(reviewerReportId)
    }

    return () => {
      subscription.cancel()
      reviewerStatusUpdateSubscription.cancel()
    }
  }, [])

  return {
    sectionDetails
  }
}
