import React, { FunctionComponent } from 'react'
import { useAuthGuardController } from './auth-guard.controller'
import { Typography } from '@material-ui/core'

const AuthGuard: FunctionComponent = ({ children }) => {

  const { user } = useAuthGuardController()
  
  if (!user) {
    return <Typography>Authenticating..</Typography>
  }

  return (
    <div>
      {children}
    </div>
  )
}

export default AuthGuard
