import React from 'react'
import GridHead from '../../../../shared/components/report-details-grid-head'
import ReportDetailsQuestions from '../../../../shared/components/report-details-questions'
import { useVehicleSectionDetailsController } from './vehicle-section-details.controller'
import { vehicleSectionQuestions as questions } from './vehicle-section-details.content'

const VehicleSectionDetails = () => {

  const {
    sectionDetails
  } = useVehicleSectionDetailsController()

  return (
    <div>
      <GridHead />
      <ReportDetailsQuestions sectionDetails={sectionDetails} questions={questions} />
    </div>
  )
}

export default VehicleSectionDetails
