import React from 'react'
import moment from 'moment'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard'
import { Grid, Typography } from '@material-ui/core'
import { fontSizes } from '../../../../shared/theme/font-sizes.theme'
import PurpleDot from '../../../../shared/components/purple-dot'
import DetailsContentText from '../../../../shared/components/details-content-text'
import QuestionMargin from '../../../../shared/components/question-margin'
import QuestionValueMargin from '../../../../shared/components/question-value-margin'
import GridHead from '../../../../shared/components/report-details-grid-head'
import { useDateAndLocationSectionDetailsController } from './date-and-location-section-details.controller'
import { dateAndLocationSectionQuestions as questions } from './date-and-location-section-details.content'

const DateAndLocationSectionDetails = () => {

  const {
    sectionDetails
  } = useDateAndLocationSectionDetailsController()

  return (
    <div>
      <GridHead />
      {
        questions.map((question) => {
          if (!sectionDetails[question.inputFieldId]?.fieldValues || sectionDetails[question.inputFieldId]?.fieldValues.length === 0) {
            return null
          }
          return (
            <React.Fragment key={question.inputFieldId}>
                <QuestionMargin>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <div style={{ marginLeft: [3,4,5,6].includes(question.inputFieldId) ? '32px' : '0px' }}>
                        <QuestionValueMargin>
                          <Typography style={{ fontSize: fontSizes[14] }}>{question.content}</Typography>
                        </QuestionValueMargin>
                      </div>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={7}>
                      {sectionDetails[question.inputFieldId]?.fieldValues.map((field, index) => {
                        return (
                          <Grid container spacing={0} key={index}>
                            <Grid item xs={7}>
                              <QuestionValueMargin key={index}>
                                <DetailsContentText isNew={field.isNew}>{field.value}</DetailsContentText>
                                <CopyToClipboard text={field.value}>
                                  <FileCopyIcon style={{ marginLeft: '5px', width: '20px', cursor: 'pointer' }} />
                                </CopyToClipboard>
                              </QuestionValueMargin>
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={4}>
                              <QuestionValueMargin key={index}>
                                <DetailsContentText isNew={field.isNew}>{moment(field.timestamp).format("YYYY/MM/DD")}</DetailsContentText>
                                {field.isNew && <PurpleDot />}
                              </QuestionValueMargin>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </QuestionMargin>
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

export default DateAndLocationSectionDetails
