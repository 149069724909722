import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import ReviewerReportTableHead from './reviewer-reports-table-head/reviewer-reports-table-head';
import ReviewerReportsTableBody from './reviewer-reports-table-body/reviewer-reports-table-body';
import ReviewerReportsTableFooter from './reviewer-reports-table-footer/reviewer-reports-table-footer';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  }
})

const ReviewerReportsTable: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <ReviewerReportTableHead />
        <ReviewerReportsTableBody />
        <ReviewerReportsTableFooter />
      </Table>
    </TableContainer>
  );
}

export default ReviewerReportsTable
