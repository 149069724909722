import moment from 'moment'
import React, { FunctionComponent } from 'react'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard'
import QuestionMargin from './question-margin'
import { Grid, Typography } from '@material-ui/core'
import { fontSizes } from '../theme/font-sizes.theme'
import QuestionValueMargin from './question-value-margin'
import DetailsContentText from './details-content-text'
import PurpleDot from './purple-dot'
import { FormattedSectionDetails } from '../utils/format-section-details.util'
import { Question } from '../interfaces/question.interface'

const ReportDetailsQuestions: FunctionComponent<{
  sectionDetails: FormattedSectionDetails;
  questions: Array<Question>;
}> = ({ sectionDetails, questions }) => {
  return (
    <>
      {questions.map((question) => {
        if (!sectionDetails[question.inputFieldId]?.fieldValues || sectionDetails[question.inputFieldId]?.fieldValues.length === 0) {
          return null
        }
        return (
          <QuestionMargin key={question.inputFieldId}>
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <QuestionValueMargin>
                  <Typography style={{ fontSize: fontSizes[14] }}>{question.content}</Typography>
                </QuestionValueMargin>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={7}>
                {sectionDetails[question.inputFieldId]?.fieldValues.map((field, index) => {
                  return (
                    <Grid container spacing={0} key={index}>
                      <Grid item xs={7}>
                        <QuestionValueMargin key={index}>
                          <DetailsContentText isNew={field.isNew}>{field.value}</DetailsContentText>
                          <CopyToClipboard text={field.value}>
                            <button style={{ border: 'none', background: 'none' }}>
                              <FileCopyIcon style={{ marginLeft: '5px', width: '20px', cursor: 'pointer' }} />
                            </button>
                          </CopyToClipboard>
                        </QuestionValueMargin>
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={4}>
                        <QuestionValueMargin key={index}>
                          <DetailsContentText isNew={field.isNew}>{moment(field.timestamp).format("YYYY/MM/DD")}</DetailsContentText>
                          {field.isNew && <PurpleDot />}
                        </QuestionValueMargin>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </QuestionMargin>
        )
      })}
    </>
  )
}

export default ReportDetailsQuestions
