import React from 'react'
import moment from 'moment'
import { Accordion, AccordionSummary, Typography, AccordionDetails, withStyles, Table, TableHead, TableRow, TableCell, TableBody, Grid } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colorPalette from '../../../shared/theme/color-palette.theme';
import { fontSizes } from '../../../shared/theme/font-sizes.theme';
import { useReportDetailsStatusHistoryController } from './report-details-status-history.controller';

const StyledAccordion = withStyles({
  root: {
    borderTop: `1px solid ${colorPalette.neonPurple}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ReportDetailsStatusHistory = () => {

  const {
    statusUpdateHistory,
    submitDate
  } = useReportDetailsStatusHistoryController()

  return (
    <div>
      <StyledAccordion square={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: colorPalette.neonPurple }} />}>
          <div style={{ padding: '5px 0' }}>
            <Typography style={{ fontSize: fontSizes[18], fontWeight: 'bold' }}>Status History</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Typography>Status</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>Date changed (YYYY/MM/DD)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Changed by (username)</Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: '3px', background: colorPalette.lightGrey, margin: '10px 0' }} />
            </Grid>
            {statusUpdateHistory.map((historyItem, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={3}>
                    <div style={{ padding: '10px 0' }}>
                      <Typography style={{ fontSize: fontSizes[14] }}>{historyItem.status}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ padding: '10px 0' }}>
                      <Typography style={{ fontSize: fontSizes[14] }}>{!historyItem.timestamp ? submitDate : moment(historyItem.timestamp).format("YYYY/MM/DD") }</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ padding: '10px 0' }}>
                      <Typography style={{ fontSize: fontSizes[14] }}>{historyItem.changedBy}</Typography>
                    </div>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </div>
  )
}

export default ReportDetailsStatusHistory
