import React, { FunctionComponent } from 'react'
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core';
import colorPalette from '../../../../shared/theme/color-palette.theme';
import { ReviewStatusType } from '../../../models/reviewer-report.model';

const ReviewerStatusChip: FunctionComponent<{
  status: ReviewStatusType
}> = ({ status }) => {

  const chipColorSelector = (status: ReviewStatusType) => {
    switch (status) {
      case 'Unopened':
        return colorPalette.black
      case 'In progress':
        return colorPalette.grey
      case 'Reviewed':
        return colorPalette.grey
      case 'Submitted':
        return colorPalette.grey
    }
  }

  const StyledChip = withStyles({
    root: {
      color: colorPalette.white,
      background: chipColorSelector(status)
    }
  })(Chip)

  return (
    <StyledChip size="medium" label={status} />
  )
}

export default ReviewerStatusChip
