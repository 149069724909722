import { useEffect, useState } from "react"
import ServiceLocator from "../../../shared/service-locator"
import { ReviewerReportDetailsSummary } from "../../models/reviewer-report-details-summary.model"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService

export function useReportDetailsSummaryController() {

  const [
    reviewerReportDetailsSummary,
    setReviewerReportDetailsSummary
  ] = useState<ReviewerReportDetailsSummary|null>(null)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {

    const subscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      setReviewerReportDetailsSummary(reviewerReportDetailsService.reviewerReportDetailsSummary)
      setIsLoading(false)
    })

    reviewerReportDetailsService.getReviewerReportDetailsSummary()

    return () => {
      subscription.cancel()
    }
  }, [])

  return {
    isLoading,
    reviewerReportDetailsSummary,
    deleteReportData: async function() {
      setIsLoading(true)
      try {
        await reviewerReportDetailsService.deleteReportData()
      } finally {
        setIsLoading(false)
      }
    }
  }
}
