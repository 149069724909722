import React, { FunctionComponent } from 'react'

const Page: FunctionComponent = ({ children }) => {
  return (
    <div role="main" style={{ width: '90%', margin: '0 auto', paddingTop: '50px' }}>
      {children}
    </div>
  )
}

export default Page
