import { ReviewerReport } from "../../../../models/reviewer-report.model"
import { useHistory } from "react-router-dom"
import ServiceLocator from "../../../../../shared/service-locator"

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService

export function useOpenReportButtonController(reviewerReport: ReviewerReport) {

  const history = useHistory()

  const handleClick = async () => {
    reviewerReportDetailsService.setTargetReportCode(reviewerReport.shortCode)
    reviewerReportDetailsService.setTargetReviewerReportId(reviewerReport.reviewerReportId)
    history.push('/report-details')
  }

  return {
    handleClick
  }
}
