import { ServiceConfig } from "./service-config.interface";

class MainServiceConfig implements ServiceConfig {
  private _baseApiEndpoint: string

  private _reviewerApiRoute: string = 'reviewer'

  constructor() {
    this._baseApiEndpoint = process.env.REACT_APP_BASE_URL!
  }

  public get baseApiEndpoint() {
    return this._baseApiEndpoint
  }

  public get reviewerApiEndpoint() {
    return this._baseApiEndpoint + this._reviewerApiRoute
  }
}

export default MainServiceConfig
