import { useState, ChangeEvent, useEffect } from "react"
import ServiceLocator from "../../../shared/service-locator"
import { sanitizeReportCode } from "../../../shared/utils/sanitize-report-code"

const reviewerReportsManager = ServiceLocator.instance.reviewerReportsManager

export function useReportCodeSearchBoxController() {
  const [ reportCode, setReportCode ] = useState<string>('')

  useEffect(() => {
    const subscription = reviewerReportsManager
      .subscribeToReportCodeSearchValueUpdate(() => {
        setReportCode(reviewerReportsManager.reportCodeSearchValue)
      })

    return () => {
      subscription.cancel()
    }
  }, [])

  const handleReportCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    reviewerReportsManager.setReportCodeSearchValue(e.target.value)
  }

  const handleSearchReport = () => {
    if (reportCode !== '') {
      const sanitizedReportCode = sanitizeReportCode(reportCode)
      reviewerReportsManager.getReviewerReport(sanitizedReportCode)
    }
    else {
      reviewerReportsManager.getReviewerReports()
    }
  }

  return {
    reportCode,
    handleReportCodeChange,
    handleSearchReport
  }
}
