import React, { useState } from 'react'
import moment from 'moment'
import {
  Typography,
  Grid,
  Paper,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  Snackbar
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useHistory } from 'react-router-dom'
import { fontSizes } from '../../../shared/theme/font-sizes.theme'
import { useReportDetailsSummaryController } from './report-details-summary.controller'
import { formatShortCodeForDisplay } from '../../../shared/utils/format-short-code-for-display.util'
import ReportCodeDisplay from './report-code-display'
import ReviewStatusDropdown from './review-status-dropdown/review-status-dropdown'
import colorPalette from '../../../shared/theme/color-palette.theme'
import FullScreenLoader from '../../../shared/components/full-screen-loader'

const ReportDetailsSummary = () => {

  const history = useHistory()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const {
    isLoading,
    reviewerReportDetailsSummary,
    deleteReportData,
  } = useReportDetailsSummaryController()

  if (!reviewerReportDetailsSummary) {
    return <FullScreenLoader loading={isLoading} />
  }

  const deleteData = async () => {
    setDialogOpen(false)
    try {
      await deleteReportData()
      // navigate back to overview once deleted
      history.push('/overview')
    } catch (e) {
      setErrorMessage('There was a problem deleting report data. Make sure the report status is "Submitted" and there are no new changes and try again.')
    }
  }

  return (
    <div>
      <Snackbar
        open={errorMessage !== undefined}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={(e, reason) => setErrorMessage(undefined)}
      >
        <Alert severity="error">{ errorMessage }</Alert>
      </Snackbar>
      <Dialog open={dialogOpen}>
        <DialogTitle>Delete Report Data</DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: fontSizes[16], fontWeight: 'bold' }}>
            Are you sure you want to delete all stored data for this report? This operation cannot be undone.
          </Typography>
          <Typography style={{ fontSize: fontSizes[16] }}>
            This operation will delete all user inputted information associated to this report but will keep
            the report code, status and comments so if new information is submitted in the future it can be
            associated with the same report.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteData} color="secondary">
            Delete All Data
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ReportCodeDisplay reportCode={formatShortCodeForDisplay(reviewerReportDetailsSummary.shortCode)} />
        <div>
          <Button
            style={{ marginBottom: '24px' }}
            variant="contained"
            color="secondary"
            onClick={() => setDialogOpen(true)}
          >
            DELETE REPORT DATA
          </Button>
          <ReviewStatusDropdown />
        </div>
      </div>
      <div style={{ margin: '24px 0' }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper style={{ background: colorPalette.tintedWhite, padding: '20px', boxSizing: 'border-box', height: '100%' }} elevation={1}>
              <Typography style={{ fontSize: fontSizes[12], color: colorPalette.grey }}>Date Submitted (YYYY/MM/DD)</Typography>
              <Typography style={{ fontSize: fontSizes[16] }}>{reviewerReportDetailsSummary.submitDate}</Typography>
              {
                reviewerReportDetailsSummary.purgeDate && (
                  <>
                    <Typography style={{ fontSize: fontSizes[12], color: colorPalette.grey }}>Report Data Purged on (YYYY/MM/DD)</Typography>
                    <Typography style={{ fontSize: fontSizes[16] }}>{reviewerReportDetailsSummary.purgeDate}</Typography>
                  </>
                )
              }
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper style={{ background: colorPalette.tintedWhite, padding: '20px', boxSizing: 'border-box', height: '100%' }} elevation={1}>
              <div>
                <Typography style={{ fontSize: fontSizes[12], color: colorPalette.grey }}>Status</Typography>
                <Typography style={{ fontSize: fontSizes[16] }}>{reviewerReportDetailsSummary.reviewerStatus}</Typography>
              </div>
              <div style={{ paddingTop: '8px' }}>
                <Typography style={{ fontSize: fontSizes[12], color: colorPalette.grey }}>Changed On (YYYY/MM/DD)</Typography>
                <Typography style={{ fontSize: fontSizes[16] }}>{reviewerReportDetailsSummary.updatedOn ? moment(reviewerReportDetailsSummary.updatedOn).format("YYYY/MM/DD") : 'N/A'}</Typography>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper style={{ background: colorPalette.tintedWhite, padding: '20px', boxSizing: 'border-box', height: '100%' }} elevation={1}>
              <Typography style={{ fontSize: fontSizes[12], color: colorPalette.grey }}>Last Updated By</Typography>
              <Typography style={{ fontSize: fontSizes[16] }}>{reviewerReportDetailsSummary.lastUpdatedBy || 'N/A'}</Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <FullScreenLoader loading={isLoading} />
    </div>
  )
}

export default ReportDetailsSummary
