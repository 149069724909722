import { ReviewStatusType } from "../../../../overview/models/reviewer-report.model";
import { useState, useEffect } from "react";
import ServiceLocator from "../../../../shared/service-locator";

const reviewerReportDetailsService = ServiceLocator.instance.reviewerReportDetailsService

export function useReviewStatusDropdown() {
  const [reviewStatusType, setReviewStatusType] = useState<ReviewStatusType>(
    reviewerReportDetailsService.reviewerReportDetailsSummary?.reviewerStatus || 'Unopened'
  );

  useEffect(() => {
    const subscription = reviewerReportDetailsService.subscribeToReviewerReportDetailsSummaryUpdate(() => {
      setReviewStatusType(reviewerReportDetailsService.reviewerReportDetailsSummary?.reviewerStatus || 'Unopened')
    })
    return subscription.cancel
  }, [])

  const handleSelectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const status = event.target.value as ReviewStatusType
    reviewerReportDetailsService.updateReviewerStatus(status)
  };

  return {
    reviewStatusType,
    handleSelectionChange
  }
}
