import React, { FunctionComponent } from 'react'

const QuestionMargin: FunctionComponent = ({ children }) => {
  return (
    <div style={{ padding: '20px 0' }}>
      {children}
    </div>
  )
}

export default QuestionMargin
